import React from 'react';

import { GetStarted } from "../GetStarted";

import './styles.scss';

export const SoftwareFor = React.forwardRef((props, ref) => {
  return (<section className='container'>
    <div>
      <h1 className='sectionTitle'>
        State-of-the-art <br/>
        software solutions <br/>
        for private satellites
      </h1>
      <p className='info'>
        We offer custom solutions for searching,
        processing, and analyzing large amounts
        of satellite-derived data collected from
        the Earth's surface.
      </p>
    </div>
    <GetStarted ref={ref}/>
  </section>)
});

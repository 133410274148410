import React from "react";
import { Link } from "react-scroll";

import { ReactComponent as Logo} from "../../assets/logo.svg";

import { SECTIONS_NAMES } from "../../constants";

import './styles.scss';

export const Footer = () => (
  <footer className='footer'>
    <Logo className='logo'/>
    <ul className='footerNavigation'>
      <Link to={SECTIONS_NAMES.useCases} className='item' smooth>
        Use cases
      </Link>
      <Link to={SECTIONS_NAMES.features} className='item' smooth>
        Features
      </Link>
      <Link to={SECTIONS_NAMES.contacts} className='item' smooth>
        Contacts
      </Link>
    </ul>
  </footer>
);

import React from "react";

import { ReactComponent as Arrow } from "../../assets/icons/arrow-sideway.svg";
import { SLIDER_INFO_TEXT } from "../UseCases/slidersConfigsAndData";

import './styles.scss';

export const ReadMore = ({ isShow, onShow, readMoreSelectedId, coordinates }) => {
  const { title, info} = SLIDER_INFO_TEXT.find(({_id}) => _id === readMoreSelectedId );
  const { x, y } = coordinates;
  return (
    <div>
      {isShow && (
        <div className='ReadMoreContainer' style={{ top: y, left: x}}>
        <div className='popup' >
          <Arrow className='close' onClick={() => onShow(readMoreSelectedId)}/>
          <h2 className='popupTitle'>{title}</h2>
          <p className='popupInfo'>{info}</p>
        </div>
        </div>
      )}
    </div>
  )
};

import React  from 'react';

import { Header } from "../../components/Header";
import { SoftwareFor } from "../../components/SoftwareFor";
import { UseCases } from "../../components/UseCases";
import { Features } from "../../components/Features";
import { Contacts } from "../../components/Contacts";
import { ContactForm } from "../../components/ContactForm";
import { Footer } from "../../components/Footer";

import './styles.scss';

export const MainContent = () => {
  const refContactForm = React.createRef();
  return (<>
    <div className='mainContent'>
      <Header />
      <SoftwareFor ref={refContactForm} />
    </div>
    <UseCases />

    <div className='mainContent'>
      <Features />
      <Contacts />
      <ContactForm ref={refContactForm} />
      <Footer />
    </div>
  </>)
};

import React, { useRef, useState } from "react";

import { SvgIcon }  from "../SvgIcon";
import { GetStarted } from "../GetStarted";

import { ReactComponent as Logo } from "../../assets/logo_big.svg";
import { ReactComponent as Location } from "../../assets/icons/location_ic.svg";
import { ReactComponent as Mail } from "../../assets/icons/mail_ic.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone_small_ic.svg";
import { ReactComponent as Facebook } from "../../assets/icons/fluv_icon.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/linkedin_ic.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram_ic.svg";

import { useForm } from "../../hooks/useForm";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { validate } from "../../validation";
import ContactProvider from '../../api/contact'

import './styles.scss';

const contactProvider = new ContactProvider();

export const ContactForm = React.forwardRef((props, ref) => {

  const submitForm = (data) => {
    contactProvider.sendContactForm(data).then(res => {
      if (res.data.success) {
        setIsShow(true);
        setIsSubmitting(false);
        setValues({});
      }
    })
    .catch(err => {
      console.warn(err);
      setPopUpText('Something went wrong. Please, try again later.');
      setIsShow(true);
      setIsSubmitting(false);
    });
  };

  const onSubmit = () => {
    submitForm (values);
  };

  const refPopUp = useRef();
  const [isShow, setIsShow] = useState(false);
  const onHidePopUp = () => {
    setIsShow(false)
  };
  const [popUpText, setPopUpText] = useState('Thank you for filling up the form!');
  useOnClickOutside(refPopUp, () => setIsShow(false));

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setIsSubmitting,
    setValues,
  } = useForm(onSubmit, validate);

  return (
    <section ref={ref} className='contactForm'>
      {isShow && (
        <div className='successPopUpContainer'>
          <div className="successPopUp" ref={refPopUp}>
            <span className='successPopUpText'>{popUpText}</span>
            <div className='buttonOk' onClick={onHidePopUp}>Ok</div>
          </div>
        </div>
      )}

      <div className='contacts'>
        <div className="logo">
          <Logo/>
        </div>

        <div className='contactsLinks'>
          <SvgIcon Component={Location} href='/' text='Ukraine, Kharkiv'/>
          <SvgIcon Component={Mail} href='mailto:georgiy.gres@fluvius.co' text='georgiy.gres@fluvius.co'/>
          <SvgIcon Component={Phone} href='tel: +38 093 139 97 15' text='+380931399715'/>
        </div>


        <div className="social">
          <SvgIcon
            Component={Facebook}
            href='https://fluvius.co/'
            text=''
            iconStyle={{width: 20, height: 20}}
            target='_blank'
          />
          <SvgIcon
            Component={Linkedin}
            href='https://www.linkedin.com/company/fluvius-sat/'
            iconStyle={{width: 20, height: 20}}
            target="_blank"
          />
          <SvgIcon
            Component={Instagram}
            href='https://www.instagram.com/fluvius.company/'
            iconStyle={{width: 20, height: 20}}
            target='_blank'
          />
        </div>
      </div>

      <div className='formContainer'>
        <div className='title'>
          <span>Ready?</span>
        </div>
          <form onSubmit={handleSubmit} className='form'>
            <div className="field">
              <div className="control">
                <input
                  autoComplete="off"
                  className={`input ${errors.email && 'danger'}`}
                  name="email"
                  onChange={handleChange}
                  value={values.email || ''}
                  placeholder='Email address*'
                />
              </div>

              <div className="control">
                <input
                  autoComplete="off"
                  className={`input ${errors.phone && 'danger'}`}
                  name="phone"
                  onChange={handleChange}
                  value={values.phone || ''}
                  placeholder='Phone number'
                />
              </div>

              <div className='name'>
                <div className="control">
                  <input
                    autoComplete="off"
                    className={`input ${errors.firstName && 'danger'}`}
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName || ''}
                    placeholder='First name*'
                  />
                </div>

                <div className="control">
                  <input
                    autoComplete="off"
                    className={`input ${errors.lastName && 'danger'}`}
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName || ''}
                    placeholder='Last name'
                  />
                </div>
              </div>
            </div>

            <div className='submitContainer'>
              <GetStarted onClick={() => handleSubmit()} style={{ marginTop: 0}}/>
              <span className='requiredFields'>* Required fields</span>
            </div>

          </form>
      </div>
    </section>
  );
});

import React from 'react';
import 'normalize.css';

import './App.scss';

import { BackgroundApp } from "./containers/Background";
import { MainContent } from "./containers/MainContent";

function App() {
  return (
    <BackgroundApp className="App">
      <MainContent/>
    </BackgroundApp>
  );
}

export default App;

import React from "react";

import ArrowRight from "../../assets/arrow-right.svg";
import ArrowLeft from "../../assets/arrow-left.svg";

const Right = props => (
  <div>
    <img alt="Next" src={ArrowRight} onClick={props.onClick} className="slick-arrow slick-next"/>
  </div>
)

const Left = props => (
  <div>
    <img alt="Previous" src={ArrowLeft} onClick={props.onClick} className='slick-arrow slick-prev'/>
  </div>
)

const sliderDefaultConfig = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const SLIDER_CONFIGS = {
  laptop: {
    ...sliderDefaultConfig,
    arrows: false,
    swipe: false
  },
  info: {
    ...sliderDefaultConfig,
    vertical: true,
    nextArrow: <Right />,
    prevArrow: <Left />
  },
  text: {
    ...sliderDefaultConfig,
    fade: true,
    arrows: false,
  },
};

export const SLIDER_INFO_TEXT = [
  {
    _id: 1,
    description: 'Utilizing satellites allows to estimate soil-water availability as precisely as never before. With this input at hand,',
    title: 'Agritech',
    info: 'Utilizing satellites allows to estimate soil-water availability as precisely as never before. With this input at hand, monitoring and forecasting droughts become  more  accurate  and  enjoyable.  What’s  more,  the  use  of  the satellite-derived   data   facilitates   mapping   vegetation   greenness   and controlling plant health status.'
  },
  {
    _id: 3,
    description: 'Over the last five decades, space agencies have sent thousands of space crafts, space capsules, or satellites to the universe.',
    title: 'Weather forecasting',
    info: 'Over the last five decades, space agencies have sent thousands of space crafts, space capsules, or satellites to the universe. Weather forecasters make  predictions  on  the  natural  calamities  based  on  observations  from these satellites.'
  },
  {
    _id: 2,
    description: 'How to get representation of a certain part on the Earth’s surface? While aerial photography with its analog images can come in handy in,',
    title: 'Surface Imaging',
    info: 'How to get representation of a certain part on the Earth’s surface? While aerial photography with its analog images can come in handy in certain cases, satellite-derived images have much more to offer. Such images are taken with the use of electronic sensors. Thus, they are digital. With plenty of innovative tools and technics for interaction with digital files, satellite-taken pictures can be certainly called a breakthrough.'
  },

];

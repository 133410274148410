import React from 'react';
import './styles.scss';

export const BackgroundApp = ({ children }) => (
  <main className='mainContainer'>
    <div className='containerBg'>
      {children}
    </div>
  </main>
)

const REGEXP_PHONE = RegExp(/^\+{0,1}[0-9]*$/);
const REGEXP_EMAIL = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const REGEXP_NAME = RegExp(/([а-яё\s]{1,}|[a-z\s]{1,})/);

export const validate = (values) => {
  let errors = {};

  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!REGEXP_EMAIL.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  if (!REGEXP_PHONE.test(values.phone) && values.phone) {
    errors.phone = 'Phone number is not valid';
  }

  if (!REGEXP_NAME.test(values.firstName) || !values.firstName) {
    errors.firstName = 'First name is not valid';
  }

  if (!REGEXP_NAME.test(values.lastName) && values.lastName) {
    errors.lastName = 'First name is not valid';
  }

  return errors;
};

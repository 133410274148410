import React from "react";
import smoothscroll from 'smoothscroll-polyfill';
import { ReactComponent as Arrow } from "../../assets/icons/long-arrow.svg";
import './styles.scss';

smoothscroll.polyfill();

function handleClick(refContactForm) {
  window.scrollTo({
    top: refContactForm.current.offsetTop,
    behavior: "smooth"
}) 
}

export const GetStarted = React.forwardRef((props, ref) => {
  return(<div onClick={() => {handleClick(ref)}} className='getStartedContainer' {...props}>
    <div className='circle'/>
    <span className='text'>Get started <Arrow className='arrow'/></span>
  </div>)
})

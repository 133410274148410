import React from 'react';
import { Link } from "react-scroll";

import { SECTIONS_NAMES } from "../../constants";

import { ReactComponent as Logo } from '../../assets/logo.svg'

import './styles.scss';

export const Header = () => (
  <header className='header'>
    <div className='headerNavContainer'>
      <Logo/>
      <div className='nav navMenu'>
        <Link to={SECTIONS_NAMES.useCases} className="navItem" smooth>
          <span>Use cases</span>
        </Link>
        <Link to={SECTIONS_NAMES.features} className="navItem" smooth>
          <span>Features</span>
        </Link>
        <Link to={SECTIONS_NAMES.contacts} className="navItem" smooth>
          <span>Contacts</span>
        </Link>
      </div>
    </div>

    <div className='nav'>
      <a className="telItem" href="tel:+38 093 139 97 15">Need a call?</a>
    </div>
  </header>
);

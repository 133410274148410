import React from "react";

import { SECTIONS_NAMES } from "../../constants";

import { ReactComponent as PhoneIc } from "../../assets/icons/phone_ic.svg";
import { ReactComponent as FaceBookIc } from "../../assets/icons/facebook_ic.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/linkedin_ic.svg";
import coPhoto from '../../assets/co-min.png'
import coPhotoHover from '../../assets/co-hover-min.png'

import { SectionTitle } from "../SectionTitle";
import { SvgIcon } from "../SvgIcon";

import './styles.scss';

const CO_DATA = {
  anton: {
    name: 'Anton Yakushin',
    position: 'Co-owner and CTO',
    description: 'As a team lead, I am responsible for task management. It involves splitting major tasks into feasible assignments and delegating those to the developers. I truly enjoy sharing best practices with my team. I am all about ensuring the high overall quality of software products we make. To achieve this, I focus on choosing the best tools and cutting-edge technologies.',
    contacts: {
      phone: '+380 97 362 68 57',
      facebook: 'https://www.facebook.com/yakushinanton',
      linkedin: 'https://www.linkedin.com/in/anton-yakushin/'
    }
  },
  georgiy: {
    name: 'Georgiy Gres',
    position: 'Co-owner and Business Analyst',
    description: 'I am ready to help our clients to bring their projects up to speed quickly and smoothly. I strongly believe that effective communication over the entire course of product development is a key. I am passionate about generating and applying new ideas that can make our clients’ products more appealing to users.',
    contacts: {
      phone: '+38 093 139 97 15',
      facebook: 'https://www.facebook.com/georgiy.gres/',
      linkedin: 'https://www.linkedin.com/in/georgiygres/'
    }
  }
};

const Contact = ({ data, style, extendedClassName = '' }) => {
  const { name, position, description, contacts } = data;

  return (
    <div className={`contact ${extendedClassName}`} style={style} >
      <div className='mainInfo'>
        <span className='name'>{name}</span>
        <span className='position'>{position}</span>
        <div className='contacts'>
          <SvgIcon
            svgClassName="contact_ic"
            Component={PhoneIc}
            href={`tel:${contacts.phone}`}
          />
          <SvgIcon
            svgClassName="contact_ic faceBook"
            Component={FaceBookIc}
            href={contacts.facebook}
            target="_blank"
          />
          <SvgIcon
            svgClassName="contact_ic"
            Component={Linkedin}
            href={contacts.linkedin}
            target="_blank"
          />
        </div>
      </div>

      <p className='description'>{description}</p>
    </div>
  )
};

export const Contacts = () => (
  <section className='contacts' id={SECTIONS_NAMES.contacts}>
    <SectionTitle title='Contacts' />
    <div className='coPhoto'>
      <div className='coPhotoWrapper'>
        <img src={coPhoto} alt="" />
        <img src={coPhotoHover} alt="" className="hoverImg" />
      </div>
    </div>

    <div className='contactsContainer'>
      <Contact data={CO_DATA.anton} style={{ textAlign: '' }} extendedClassName='left' />
      <Contact data={CO_DATA.georgiy} extendedClassName='right' />
    </div>
  </section>
);

import React, { useRef, useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { useOnClickOutside } from "../../hooks/useOnClickOutside";

import { SLIDER_CONFIGS, SLIDER_INFO_TEXT } from "./slidersConfigsAndData";

import { ReactComponent as Arrow } from "../../assets/icons/arrow-sideway.svg";

import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg";

import slide1 from '../../assets/slider/slide1-min.jpg'
import slide2 from '../../assets/slider/agriculture-min.jpg'
import slide3 from '../../assets/slider/surface_img-min.jpg'

import { SectionTitle } from "../SectionTitle";
import { ReadMore } from "../ReadMore";

import './styles.scss';

export const UseCases = () => {
  const refPopUp = useRef();
  const [isShow, setIsShow] = useState(false);
  const [readMoreSelectedId, setReadMoreSelectedId] = useState(1);

  const onShow = id => {
    setReadMoreSelectedId(id);
    setIsShow(!isShow);
  };

  useOnClickOutside(refPopUp, () => setIsShow(false));

  const [laptopSliderRef, setLaptopSliderRef] = useState({});
  const [currentSlide, ] = useState();
  const [sliderInfoRef, ] = useState();
  const [bottomSliderRef, setBottomSliderRef] = useState();
  const [popUpCoordinates, setPopUpCoordinates] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (sliderInfoRef) sliderInfoRef.slickGoTo(currentSlide)
  }, [currentSlide, sliderInfoRef]);

  return (
    <section className='useCases'>
      <div ref={refPopUp}>
        <ReadMore
          isShow={isShow}
          onShow={onShow}
          readMoreSelectedId={readMoreSelectedId}
          coordinates={popUpCoordinates}
        />
      </div>

      <div className='useCasesContainer'>
        <div className='sliderMobileNavWrapper'>
          <SectionTitle title='use cases' />
          <div className='sliderMobileNav'>
            <ArrowLeft
              onClick={laptopSliderRef.slickPrev}
              className="ArrowLeft"
            />
            <ArrowRight
              className="arrowRight"
              onClick={laptopSliderRef.slickNext}
            />
          </div>
        </div>

        <div className='content'>
          <div className='laptop'>
            <Slider
              className='laptopSlider'
              {...SLIDER_CONFIGS.laptop}
              asNavFor={bottomSliderRef}
              ref={slider => setLaptopSliderRef(slider)}
            >
              <img src={slide1} alt="Agriculture" />
              <img src={slide2} alt="Surface" />
              <img src={slide3} alt="Weather" />
            </Slider>
          </div>

          <div className='sliderInfoContainer'>
            <Slider
              {...SLIDER_CONFIGS.info}
              adaptiveHeight
              className='sliderInfo'
              asNavFor={laptopSliderRef}
              ref={slider => setBottomSliderRef(slider)}
            >
              {SLIDER_INFO_TEXT.map(({ _id, description, title }) => (
                <span className='info' key={_id}>
                  <SectionTitle title={title} />
                  <p className="slider-text">{description}
                    <span className='infoRead' onClick={(e) => {
                      setPopUpCoordinates({ x: e.pageX, y: e.pageY });
                      onShow(_id)
                    }}>
                      Read more <Arrow className='openReadMore' />
                    </span>
                  </p>
                </span>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
};

import React from "react";

import { SectionTitle } from "../SectionTitle";

import { SECTIONS_NAMES } from "../../constants";

import { ReactComponent as Experience } from "../../assets/icons/experience_ic.svg";
import { ReactComponent as TurnKey } from "../../assets/icons/turn_key_ic.svg";
import { ReactComponent as GroundStation } from "../../assets/icons/ground_station_ic.svg";
import { ReactComponent as Scientific } from "../../assets/icons/scientific_activities_ic.svg";

import './styles.scss'

export const Features = () => (
  <div className='featuresContainer' id={SECTIONS_NAMES.features}>
    <SectionTitle title='features' />
    {window.matchMedia("(max-width: 650px)").matches ?
            <div className='features'>
            <div>
            <div className='feature'>
                <Scientific className='scientific_ic' />
                <span className='nameFeature'>Scientific activities</span>
                <p className='infoFeature'>
                  Fluvius draws on the expertise of more than 3 universities and
                  6 faculties related to  telecommunications and  rocket  building.
                  We  are proud  of  our productive collaboration with their best talents.
              </p>
              </div>
    
              <div className='feature'>
                <TurnKey className='turnKey_ic' />
                <span className='nameFeature'>Work with us</span>
                <p className='infoFeature'>
                  Fluvius is open to different types of cooperation.
                  If your company got used to operating and managing
                  projects by your side, we are ready to offer our
                  determined and highly-skilled remote workers
                  for outstaff. Alternatively, you might choose
                  turn-key solution and take a dedicated team with
                  further maintenance.
              </p>
              </div>
            </div>
    
            <div className='indent'>
              <div className='feature'>
                <GroundStation className='groundStation_ic' />
                <span className='nameFeature'>Amazon Ground Station</span>
                <p className='infoFeature'>
                  Working  hand  in  hand  with  AWS  we  get  the  first-rate
                  infrastructure  and platform services. Access to the operation
                  of their Ground Station service allows  us  to  meet  our
                  clients’  project  needs  with  almost  unlimited opportunities.
              </p>
              </div>
              <div className='feature'>
                <Experience className='experience_ic' />
                <span className='nameFeature'>Experience</span>
                <p className='infoFeature'>
                  Fluvius  is  a  development  company  with  plenty  of  proven
                  commercial experience  and  96%  satisfactory  rate.  We  are
                  qualified  in  working  on software systems of various complexity
                  with prosperous partners from all over the world.
              </p>
              </div>
            </div>
          </div> :
      <div className='features'>
        <div>
          <div className='feature'>
            <Experience className='experience_ic' />
            <span className='nameFeature'>Experience</span>
            <p className='infoFeature'>
              Fluvius  is  a  development  company  with  plenty  of  proven
              commercial experience  and  96%  satisfactory  rate.  We  are
              qualified  in  working  on software systems of various complexity
              with prosperous partners from all over the world.
          </p>
          </div>

          <div className='feature'>
            <TurnKey className='turnKey_ic' />
            <span className='nameFeature'>Work with us</span>
            <p className='infoFeature'>
              Fluvius is open to different types of cooperation.
              If your company got used to operating and managing
              projects by your side, we are ready to offer our
              determined and highly-skilled remote workers
              for outstaff. Alternatively, you might choose
              turn-key solution and take a dedicated team with
              further maintenance.
          </p>
          </div>
        </div>

        <div className='indent'>
          <div className='feature'>
            <GroundStation className='groundStation_ic' />
            <span className='nameFeature'>Amazon Ground Station</span>
            <p className='infoFeature'>
              Working  hand  in  hand  with  AWS  we  get  the  first-rate
              infrastructure  and platform services. Access to the operation
              of their Ground Station service allows  us  to  meet  our
              clients’  project  needs  with  almost  unlimited opportunities.
          </p>
          </div>
          <div className='feature'>
            <Scientific className='scientific_ic' />
            <span className='nameFeature'>Scientific activities</span>
            <p className='infoFeature'>
              Fluvius draws on the expertise of more than 3 universities and
              6 faculties related to  telecommunications and  rocket  building.
              We  are proud  of  our productive collaboration with their best talents.
          </p>
          </div>
        </div>
      </div>
    }
  </div>
);

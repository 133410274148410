import React from "react";

import './styles.scss'

export const SvgIcon = ({
  Component,
  href,
  text,
  iconStyle={},
  target='',
  svgClassName=''
}) => (
  <a href={href} className='iconSvg' target={target}>
    <Component {...iconStyle} className={svgClassName}/>
    {text && <span className='text'>{text}</span>}
  </a>
);
